import React from "react"
import {Link} from 'gatsby';
import Layout from "../../components/actiontimer/layout"
// import logo from "../../images/actiontimer/applogo.svg"
import seologo from "../../images/actiontimer/appicon256.png"
import Seo from "../../components/SEO"

import * as styles from '../../css/actionfaq.module.css'

function PrivacyPage(props) {

    const seoImage = {
		src: seologo,
		height: 256,
		width: 256,
		alt: "ActionTimer for Mac app logo",
    };

    return (
    <Layout>
        <Seo
            siteTitle={'ActionTimer FAQ'}
            seoTitle='ActionTimer Frequently asked questions'
            path={props.location.pathname}
            metaImage={seoImage}
            icon={seologo}
            description='ActionTimer for Mac - Frequently asked questions'
            keywords={["ActionTimer", "FAQ"]}
            datePublished="09-02-2020"
            dateModified="11-01-2022"
        />
        
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1 style={{color: 'chocolate'}}>ActionTimer for Mac</h1>
                    <h2>Frequently Asked Questions</h2>
                </div>

                {/* <div className={styles.faqSection}> */}
                <h2 className={styles.header}>In-App Purchases</h2>
                <div className={styles.faqContent}>
                    <ol>
                        <li><h3 className={styles.title}>Can I use the In-App purchases on more than one Mac?</h3></li>
                        <p className={styles.desc}>
                            Yes, you can use in-app purchases on another Mac by using the same Apple Id that you originally used to purchase the item.
                        </p>

                        <li><h3 className={styles.title}>Why is the In-App purchase store in Preferences not showing any available purchases?</h3></li>
                            <p className={styles.desc}>
                                An active Internet connection is needed to download information for products that are available for purchase. Please check the Internet connection.
                            </p>

                        <li><h3 className={styles.title}>My In-App purchase bundle actions are not showing up in the app. What can I do?</h3></li>
                        <p className={styles.desc}>
                            You can try restoring your purchases from the Action menu. Actions > Restore Purchases, or from Preferences > General.
                        </p>

                        <li><h3 className={styles.title}>Why is Restore Purchases not working?</h3></li>
                        <p className={styles.desc}>
                            Please make sure you have an active Internet connection. Secondly, Restore will only work if (a) there was actually a purchase made via a valid Apple Id, and (b) you're using the same Apple Id while attempting to Restore. Delete the app from your Mac and download again. It should ask for your Apple Id when you run the app.
                        </p>
                    </ol>
                </div>
                
                <h2 className={styles.header}>Actions</h2>
                <div className={styles.faqContent}>
                    <ol>
                        <li><h3 className={styles.title}>Why can't I run multiple timers?</h3></li>
                        <p className={styles.desc}>
                            Unlimited timers are available with any bundle purchase.
                        </p>

                        <li><h3 className={styles.title}>Why don't I see any actions in the Action list?</h3></li>
                        <p className={styles.desc}>
                            You may need to install the default actions from the action list, or from Preferences > General. If you have made an In-App purchase, the new actions should automatically be available in the Actions list. If not, you may try to Restore Purchases. See section above about In-App Purchases.
                        </p>

                        <li><h3 className={styles.title}>Why my actions are not running?</h3></li>
                        <p className={styles.desc}>
                            Some actions may need accessibility or privacy permissions before they can run. Please check you have granted the required permissions. Accessibility permissions are available at System Preferences > Privacy > Accessibility. 
                        </p>

                        <li><h3 className={styles.title}>Will the timers and actions run when the system sleeps?</h3></li>
                        <p className={styles.desc}>
                            Some timers may continue to run but some actions may not be completed if the system sleeps.
                        </p>

                        <li><h3 className={styles.title}>Will the timers and actions run when the system is shutdown and later restarts?</h3></li>
                        <p className={styles.desc}>
                            No, the timers and actions need a running system. If the computer is shutdown or restarted, the timers and their associated actions are not restored. You may need to schedule new timers with new actions.
                        </p>

                        <li><h3 className={styles.title}>Will the "Move to Trash" action delete files or folders permanently?</h3></li>
                        <p className={styles.desc}>
                            ActionTimer will attempt to move the files or folders selected to the system Trash and will not delete anything permanently. So, if you need to restore something, you can restore it from the Trash. However, if there is no space left on the device, the files or folders might be deleted permanently.
                        </p>

                        <li><h3 className={styles.title}>I use multiple monitors / screens. How will "Take Screenshot" action work in this case? </h3></li>
                        <p className={styles.desc}>
                            Screenshot will only be captured from the main or primary screen. That's usually the first assigned screen by the system. If you're not sure, you can experiment by running a Screenshot action and see which screen is captured, so you can make a decision accordingly. Capturing screenshots from multiple screens might be supported in later versions.
                        </p>

                        <li><h3 className={styles.title}>Can I select the application to open the file in?</h3></li>
                        <p className={styles.desc}>
                            No, the file will always be opened in its default assigned application. If there's no assigned application or the file has an unknown extension, ActionTimer may fail to open the file.
                        </p>

                        <li><h3 className={styles.title}>Can ActionTimer open a file from a network mount or a mounted disk?</h3></li>
                        <p className={styles.desc}>
                            Yes, as long as the mounted drive is available, and the file continue to exist at the time when action is scheduled to run.
                        </p>

                        <li><h3 className={styles.title}>What kind of files can ActionTimer can print?</h3></li>
                        <p className={styles.desc}>
                            Currently, ActionTimer supports printing PDF (.pdf) and plain text(.txt) files.
                        </p>
                    </ol>

                </div>
                    <h3 className={styles.title}>I have some other question which is not answered here.</h3>
                    <p className={styles.desc}>
                        If you have additional questions, please <Link to="/contact" style={{textDecoration: 'None', color: 'chocolate'}}>contact us</Link>.
                    </p>
            </div>
            {/* </div> */}
        </div>
    </Layout>
    )
}

export default PrivacyPage
